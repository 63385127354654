import config from 'config';
import { getTenantsList, updateTenant, getTenantsConfig } from '_api/tenants';
import { createSlice } from '@reduxjs/toolkit';
import { XHR_STATUS } from 'constants/xhr-status';

const defaultTenant = config.defaultTenant || '';

const initialState = {
  tenant: defaultTenant,
  isTenantSite: false,
  tenantConfig: {},
  tenantsList: [],
  getTenantsListLoading: false,
  getTenantsListSuccess: false,
  getTenantsListError: false,
  updateTenantRequestStatus: XHR_STATUS.idle,
};

const tenant = createSlice({
  name: 'tenant',
  initialState,
  reducers: {
    setIsTenantSite: (state, action) => {
      state.isTenantSite = action.payload;
    },
    setTenant: (state, action) => {
      state.tenant = action.payload;
    },
    setDefaultTemplateUuidForTenant: (state, action) => {
      const { tenantId, templateUuid } = action.payload;

      state.tenantsList = state.tenantsList.map(el =>
        (el.id === tenantId
          ? {
            ...el,
            default_link_template_uuid: templateUuid,
          }
          : el),
      );
    },
  },
  extraReducers: {
    [getTenantsList.pending]: state => ({
      ...state,
      updateTenantRequestStatus: XHR_STATUS.pending,
    }),
    [getTenantsList.fulfilled]: (state, action) => {
      const { data: tenantsList = [] } = action.payload ?? {};

      return {
        ...state,
        tenantsList,
        updateTenantRequestStatus: XHR_STATUS.fulfilled,
      };
    },
    [getTenantsList.rejected]: state => ({
      ...state,
      updateTenantRequestStatus: XHR_STATUS.rejected,
    }),
    [getTenantsConfig.pending]: state => ({
      ...state,
      updateTenantRequestStatus: XHR_STATUS.pending,
    }),
    [getTenantsConfig.fulfilled]: (state, action) => {
      const tenantConfig = action.payload ?? {};

      return {
        ...state,
        tenantConfig,
        updateTenantRequestStatus: XHR_STATUS.fulfilled,
      };
    },
    [getTenantsConfig.rejected]: state => ({
      ...state,
      updateTenantRequestStatus: XHR_STATUS.rejected,
    }),
    [updateTenant.pending]: state => ({
      ...state,
      updateTenantRequestStatus: XHR_STATUS.pending,
    }),
    [updateTenant.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.tenantsList = state.tenantsList.map(el =>
        (el.id === data.id
          ? {
            ...el,
            default_link_template_uuid: data.default_link_template_uuid,
          }
          : el),
      );
      state.updateTenantRequestStatus = XHR_STATUS.fulfilled;
    },
    [updateTenant.rejected]: state => ({
      ...state,
      updateTenantRequestStatus: XHR_STATUS.rejected,
    }),
  },
});

export default tenant.reducer;

export const { setIsTenantSite, setTenant } = tenant.actions;
