import {
  DashboardOutlined,
  LinkOutlined,
  EnvironmentOutlined,
  SettingOutlined,
  ToolOutlined,
  QuestionOutlined,
  BlockOutlined,
  PartitionOutlined,
} from '@ant-design/icons';
import { UserRoles } from '../pages/admin/constant';
import { useMemo } from 'react';
  
export const menuItems = [
  {
    id: 'dashboard',
    breadcrumbTitle: 'Dashboard',
    type: 'item',
    url: '/dashboard',
    icon: DashboardOutlined,
    disabled: true,
  },
  {
    id: 'links',
    breadcrumbTitle: 'Links',
    type: 'item',
    url: '/links',
    breadcrumbs: true,
    icon: LinkOutlined,
  },
  {
    id: 'groups',
    breadcrumbTitle: 'Groups',
    type: 'item',
    url: '/groups',
    breadcrumbs: true,
    icon: PartitionOutlined,
  },
  {
    id: 'domains',
    title: 'Domains',
    type: 'item',
    url: '/domains',
    icon: EnvironmentOutlined,
    disabled: true,
  },
  {
    id: 'add_user',
    type: 'hide',
    url: '/add',
    unauthorizedRoles: [UserRoles.LinkAdmin, UserRoles.StandardUser, UserRoles.ReportingUser],
  },
  {
    id: 'user_roles',
    type: 'hide',
    url: '/roles',
  },
  {
    id: 'power-pages',
    title: '',
    breadcrumbTitle: 'PowerPages',
    type: 'item',
    url: '/power-pages',
    breadcrumbs: true,
    icon: BlockOutlined,
  },
  {
    id: 'admin',
    title: 'Admin',
    url: '/admin',
    icon: SettingOutlined,
    type: 'collapse',
    children: [
      {
        id: 'attributes',
        breadcrumbTitle: 'Attributes',
        type: 'item',
        url: '/admin/attributes',
        unauthorizedRoles: [UserRoles.LinkAdmin, UserRoles.StandardUser],
        breadcrumbs: true,
        parentBrd: 'Admin',
      },
      {
        id: 'account_user',
        breadcrumbTitle: 'Account Information',
        type: 'item',
        url: '/account',
        breadcrumbs: true,
        icon: LinkOutlined,
        hideSidebar: true,
        parentBrd: 'My Account',
      },
      {
        id: 'account_domain',
        breadcrumbTitle: 'Domain Information',
        type: 'item',
        url: '/account/domain',
        breadcrumbs: true,
        icon: LinkOutlined,
        hideSidebar: true,
        parentBrd: 'My Account',
      },
      {
        id: 'password',
        breadcrumbTitle: 'Change Password',
        type: 'item',
        url: '/account/password',
        breadcrumbs: true,
        icon: LinkOutlined,
        hideSidebar: true,
        parentBrd: 'My Account',
      },
      {
        id: 'link-templates',
        title: '',
        breadcrumbTitle: 'Link Templates',
        type: 'item',
        url: '/admin/link-templates',
        unauthorizedRoles: [UserRoles.StandardUser, UserRoles.LinkAdmin],
        breadcrumbs: true,
        parentBrd: 'Admin',
      },
      {
        id: 'qr-templates',
        title: '',
        breadcrumbTitle: 'QR Templates',
        type: 'item',
        url: '/admin/qr-templates',
        unauthorizedRoles: [UserRoles.LinkAdmin, UserRoles.StandardUser],
        breadcrumbs: true,
        parentBrd: 'Admin',
      },
      {
        id: 'users',
        title: '',
        breadcrumbTitle: 'Users',
        type: 'item',
        url: '/admin/users',
        unauthorizedRoles: [UserRoles.LinkAdmin, UserRoles.StandardUser],
        breadcrumbs: true,
        parentBrd: 'Admin',
      },
      {
        id: 'root-pages',
        title: 'Root Pages',
        type: 'item',
        url: '/admin/root-pages',
        breadcrumbs: false,
        disabled: true,
        parentBrd: 'Admin',
      },
      {
        id: 'trusted-destinations',
        title: '',
        breadcrumbTitle: 'Trusted Destinations',
        type: 'item',
        url: '/admin/trusted-destinations',
        breadcrumbs: true,
        parentBrd: 'Admin',
        unauthorizedRoles: [UserRoles.LinkAdmin, UserRoles.StandardUser],
      },
      {
        id: 'pending-links',
        title: '',
        breadcrumbTitle: 'Pending Links',
        type: 'item',
        url: '/admin/pending-links',
        breadcrumbs: true,
        parentBrd: 'Admin',
        unauthorizedRoles: [UserRoles.LinkAdmin, UserRoles.StandardUser, UserRoles.ReportingUser],
      },
      {
        id: 'tags',
        title: '',
        breadcrumbTitle: 'Tags',
        type: 'item',
        url: '/admin/tags',
        breadcrumbs: true,
        parentBrd: 'Admin',
      },
      {
        id: 'ip-exclusions',
        title: '',
        breadcrumbTitle: 'IP Exclusions',
        type: 'item',
        url: '/admin/ip-exclusions',
        breadcrumbs: true,
        parentBrd: 'Admin',
        unauthorizedRoles: [UserRoles.LinkAdmin, UserRoles.StandardUser],
      },
      {
        id: 'e-link',
        title: '',
        breadcrumbTitle: 'E-Link',
        type: 'item',
        url: '/admin/e-link',
        breadcrumbs: true,
        parentBrd: 'Admin',
        unauthorizedRoles: [UserRoles.BillingManager, UserRoles.AccountAdmin],
      },
    ],
  },
  {
    id: 'tools',
    title: 'Tools',
    type: 'item',
    url: '/tools',
    icon: ToolOutlined,
    disabled: true,
  },
  {
    id: 'help',
    title: 'Help',
    url: 'https://bl.ink/help-sidebar',
    icon: QuestionOutlined,
    type: 'item',
    disabled: false,
    target: true,
    external: true,
  },
];

const useMenuItems = (currentTenantConfig = null) => useMemo(() => 
  menuItems.map((item) => {
    if (item.id === 'help') {
      return {
        ...item,
        url: currentTenantConfig?.helpPage ?? 'https://bl.ink/help-sidebar',
      };
    }
    return item;
  }), [currentTenantConfig]);

export default useMenuItems; 
