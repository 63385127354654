import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import useMenuItems from 'hooks/useMenuItems';

import NavGroup from './NavGroup';

const Navigation = () => {
  const currentTenantConfig = useSelector(state => state.tenant.tenantConfig);

  const menu = useSelector(state => state.menu);
  const menuItems = useMenuItems(currentTenantConfig);
  const mappedItems = menuItems.map(item => <NavGroup key={item.id} items={item} />);

  const { drawerOpen } = menu;

  return <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{mappedItems}</Box>;
};

export default Navigation;
