import { createSelector } from '@reduxjs/toolkit';

export const tenantListSelector = state => state.tenant.tenantsList;
export const currentTenantIdSelector = state => state.tenant.tenant;
export const currentTenantConfig = state => state.tenant.tenantConfig;
export const currentTenantSelector = createSelector(
  tenantListSelector,
  currentTenantIdSelector,
  (tenants, tenantId) => tenants.find(el => el.id === tenantId),
);
