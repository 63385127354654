import { createSlice } from '@reduxjs/toolkit';
import { createUser, getUserAllRoles, getUsersList, deleteUsers, getPaginatedUsers } from '_api/admin/users';
import cloneDeep from 'lodash/cloneDeep';
import { UserLayer } from 'pages/admin/constant';
import { XHR_STATUS } from 'constants/xhr-status';
import { UNKNOWN_ERROR } from 'constants/errorMessages';

const initialState = {
  // Users List
  usersList: null,
  usersListStatus: XHR_STATUS.idle,
  usersListMeta: [],
  getUsersLoading: false,
  getUsersSuccess: false,
  getUsersError: false,
  // account roles list
  accountRoles: [],
  // Create User
  createUserLoading: false,
  createUserSuccess: false,
  createUserError: false,
  // Delete Users
  deleteUsersLoading: false,
  deleteUsersSuccess: false,
  deleteUsersError: false,
};

const admin = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    resetCreateUserData: state => ({
      ...state,
      createUserLoading: false,
      createUserSuccess: false,
      createUserError: false,
    }),
    resetUsersListData: state => ({
      ...state,
      usersList: [],
      getUsersLoading: false,
      getUsersSuccess: false,
      getUsersError: false,
    }),
    resetDeleteUsersData: state => ({
      ...state,
      deleteUsersLoading: false,
      deleteUsersSuccess: false,
      deleteUsersError: false,
    }),
  },
  extraReducers: {
    // get user roles list
    [getUserAllRoles.pending]: state => ({
      ...state,
      usersList: null,
      getUsersLoading: true,
      getUsersSuccess: false,
      getUsersError: false,
    }),
    [getUserAllRoles.fulfilled]: (state, action) => {
      const { data: accountRoles } = action.payload ?? [];

      return {
        ...state,
        accountRoles,
        getUsersLoading: false,
        getUsersSuccess: true,
        getUsersError: false,
      };
    },
    [getUserAllRoles.rejected]: state => ({
      ...state,
      getUsersLoading: false,
      getUsersSuccess: false,
      getUsersError: true,
    }),
    // Users List
    [getUsersList.pending]: state => ({
      ...state,
      usersList: null,
      getUsersLoading: true,
      getUsersSuccess: false,
      getUsersError: false,
      usersListStatus: XHR_STATUS.pending,
    }),
    [getUsersList.fulfilled]: (state, action) => {
      const { data, userType, activeRoleDomain } = action.payload ?? {};
      const { data: usersList = [], meta: usersListMeta = [] } = data ?? null;
      const copyAccountRoles = cloneDeep(state.accountRoles);

      const checkedUserList = usersList.map((user) => {
        let isDisabled = false;
        user.roles.forEach((role) => {
          // Check for Account role users
          if (userType === UserLayer.Account) {
            if (!copyAccountRoles.some(accRole => accRole.code === role.code)) {
              isDisabled = true;
            }
          }

          // Check for Domain role users
          if (userType === UserLayer.Domain) {
            if (activeRoleDomain.domain_id === role.domain_id) {
              if (!copyAccountRoles.some(accRole => accRole.code === role.code)) {
                isDisabled = true;
              }
            }
            if (!copyAccountRoles.some(accRole => accRole.code === role.code)) {
              isDisabled = true;
            }
          }
        });
        return { ...user, isDisabled };
      }).sort((a, b) => `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`));

      return {
        ...state,
        usersList: checkedUserList,
        usersListMeta,
        getUsersLoading: false,
        getUsersSuccess: true,
        getUsersError: false,
        usersListStatus: XHR_STATUS.fulfilled,
      };
    },
    [getUsersList.rejected]: state => ({
      ...state,
      getUsersLoading: false,
      getUsersSuccess: false,
      getUsersError: true,
      usersListStatus: XHR_STATUS.rejected,
    }),
    // PaginatedUsers
    [getPaginatedUsers.pending]: state => ({
      ...state,
      usersList: null,
      getUsersLoading: true,
      getUsersSuccess: false,
      getUsersError: false,
      usersListStatus: XHR_STATUS.pending,
    }),
    [getPaginatedUsers.fulfilled]: (state, action) => {
      const { data, userType, activeRoleDomain } = action.payload ?? {};
      const { data: usersList = [], meta: usersListMeta = [] } = data ?? null;
      const copyAccountRoles = cloneDeep(state.accountRoles);

      const checkedUserList = usersList.map((user) => {
        let isDisabled = false;
        user.roles.forEach((role) => {
          // Check for Account role users
          if (userType === UserLayer.Account) {
            if (!copyAccountRoles.some(accRole => accRole.code === role.code)) {
              isDisabled = true;
            }
          }

          // Check for Domain role users
          if (userType === UserLayer.Domain) {
            if (activeRoleDomain.domain_id === role.domain_id) {
              if (!copyAccountRoles.some(accRole => accRole.code === role.code)) {
                isDisabled = true;
              }
            }
            if (!copyAccountRoles.some(accRole => accRole.code === role.code)) {
              isDisabled = true;
            }
          }
        });
        return { ...user, isDisabled };
      }).sort((a, b) => `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`));

      return {
        ...state,
        usersList: checkedUserList,
        usersListMeta,
        getUsersLoading: false,
        getUsersSuccess: true,
        getUsersError: false,
        usersListStatus: XHR_STATUS.fulfilled,
      };
    },
    [getPaginatedUsers.rejected]: state => ({
      ...state,
      getUsersLoading: false,
      getUsersSuccess: false,
      getUsersError: true,
      usersListStatus: XHR_STATUS.rejected,
    }),
    // Create User
    [createUser.fulfilled]: state => ({
      ...state,
      createUserLoading: false,
      createUserSuccess: true,
      createUserError: false,
    }),
    [createUser.rejected]: (state, action) => {
      const { message = '' } = action.error;

      return {
        ...state,
        createUserLoading: false,
        createUserSuccess: false,
        createUserError: message ?? UNKNOWN_ERROR,
      };
    },
    // Delete Users
    [deleteUsers.fulfilled]: state => ({
      ...state,
      deleteUsersLoading: false,
      deleteUsersSuccess: true,
      deleteUsersError: false,
    }),
    [deleteUsers.rejected]: (state, action) => {
      const { message = UNKNOWN_ERROR } = action.payload ?? {};

      return {
        ...state,
        deleteUsersLoading: false,
        deleteUsersSuccess: false,
        deleteUsersError: message,
      };
    },
  },
});

export const {
  resetCreateUserData,
  resetUsersListData,
  resetDeleteUsersData,
} = admin.actions;

export default admin.reducer;
